const REWARD_COLORS = [
  "#FBC6A9",
  "#FFDECC",
  //  "#FEF9F7"
];

const PRIZE_IMG = {
  0: "", // 现金
  1: "http://shuyubang.oss-cn-shenzhen.aliyuncs.com/2024-05-19/c4cc203cfb96486a94921fee52b6dc13.png", // 数育币
  2: "http://shuyubang.oss-cn-shenzhen.aliyuncs.com/2024-05-19/15818111060b4bb7bfad51345c107a02.png", // 积分
  3: "http://shuyubang.oss-cn-shenzhen.aliyuncs.com/2024-05-17/5a2f243b5d8641c7bcf944cadf4029e1.png", // vip会员
  4: "", // 抽奖机会
};

// 根据使用频率获取总次数 0-新用户仅一次,1-每日1次,2-每日2次,3-每周一次, 4-新版本更新一次
const TOTAL_COUNT = {
  0: 1,
  1: 1,
  2: 2,
  3: 1,
  4: 1,
};

// clickReceive 手动领取 autoReceive 自动领取  shareWechat 分享微信 titok 分享抖音 redBook 分享小红书 kwai 分享快手 evaluate 5星好评 englishLearningVip 英语学习会员
export function handleTaskList(taskList: any) {
  if (taskList && taskList.length) {
    return taskList.map((item: any, index: any) => {
      const { actionName, useFrequency } = item || {};
      // TDO 先把autoReceive按click处理
      const type = actionName
      // const type = actionName || "click";
      // 添加颜色
      const color = '#519AF1'
      return {
        ...item,
        type,
        color,
        totalCount: (TOTAL_COUNT as any)[useFrequency], // 可领取次数
        autoReceive: actionName === 'autoReceive', // 是否自动领取
      };
    });
  } else {
    return [];
  }
}

// 获取抽奖项
export function handleLotteryRewardList(data: any) {
  if (data && data.length) {
    const rewardList = data.map((item: any, index: any) => {
      const { prizeType, prizeName } = item || {};
      // 添加颜色
      const getColor = (index: number) => {
        // 防止收尾连续两块区域颜色一致(首尾)
        if ((data.length % REWARD_COLORS.length !== 0) && index === data.length - 1) {
          return "#FEF9F7"
        }
        const index2 = index % REWARD_COLORS.length;
        return REWARD_COLORS[index2];
      };
      // 添加图片
      const prizeImg = item.prize_img || (PRIZE_IMG as any)[prizeType];
      // 特殊处理-识别谢谢惠顾
      const isThankyou = prizeType == 99 && prizeName.includes('谢谢');
      return {
        ...item,
        color: getColor(index),
        prizeImg,
        isThankyou,
      };
    });
    return rewardList;
  } else {
    return [];
  }
}
