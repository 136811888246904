import Vue from "vue";

import {
  NoticeBar,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Popup,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Toast,
  Icon,
  PullRefresh,
  Empty,
  Form,
  Field,
  CountDown,
  Search,
  Cell,
  CellGroup,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Button,
  Dialog,
  List,
  Popover,
} from "vant";

Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(PullRefresh);
Vue.use(Empty);
Vue.use(Form);
Vue.use(Field);
Vue.use(CountDown);
Vue.use(Search);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(List);
Vue.use(Popover);
